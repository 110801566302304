<div>
  <mat-card class="example-card">
    <mat-card-header>
      <mat-card-title-group>
        <mat-card-title>Shiba Inu</mat-card-title>
        <mat-card-subtitle>Medium</mat-card-subtitle>
        <img
          mat-card-md-image
          src="https://material.angular.io/assets/img/examples/shiba2.jpg"
          alt="Image of a Shiba Inu"
        />
      </mat-card-title-group>
    </mat-card-header>
  </mat-card>
  <mat-card class="example-card">
    <mat-card-header>
      <mat-card-title-group>
        <mat-card-title>Shiba Inu</mat-card-title>
        <mat-card-subtitle>Medium</mat-card-subtitle>
        <img
          mat-card-md-image
          src="https://material.angular.io/assets/img/examples/shiba2.jpg"
          alt="Image of a Shiba Inu"
        />
      </mat-card-title-group>
    </mat-card-header>
  </mat-card>
  <mat-card>
    <h4>Current plan</h4>
  </mat-card>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Invoices</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef>No.</th>
          <td mat-cell *matCellDef="let element">{{ element.position }}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="weight">
          <th mat-header-cell *matHeaderCellDef>Weight</th>
          <td mat-cell *matCellDef="let element">{{ element.weight }}</td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="symbol">
          <th mat-header-cell *matHeaderCellDef>Symbol</th>
          <td mat-cell *matCellDef="let element">{{ element.symbol }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </mat-card-content>
  </mat-card>
  <mat-card></mat-card>
</div>
<div>
  <div>
    <h6>Auto renewal</h6>
    <p>
      All subscriptions are set to automatically renew, unless you cancel before
      the next renewal date.
    </p>
    <mat-slide-toggle [(ngModel)]="isChecked"
      >Auto renewal is turned {{ isChecked }}</mat-slide-toggle
    >
  </div>
  <div>
    <h6>Cancel subscription</h6>
    <p>
      You will lose access to all premium features in your current subscription
      plan
    </p>
    <a>Cancel your subscription</a>
  </div>
</div>
