import { Component } from '@angular/core';
import { Auth, EmailAuthCredential, EmailAuthProvider, User, authState, reauthenticateWithCredential, updatePassword } from '@angular/fire/auth';
import { traceUntilFirst } from '@angular/fire/compat/performance';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatError } from '@angular/material/form-field';
import { EMPTY, Observable, Subscription, map } from 'rxjs';

@Component({
  selector: 'dashboard-account-security',
  templateUrl: './account-security.component.html',
  styleUrls: [ './account.component.scss' ]
})
export class AccountSecurityComponent {
  changePasswordForm: FormGroup = new FormGroup({});

  private readonly userDisposable: Subscription | undefined;
  public readonly user: Observable<User | null> = EMPTY;

  constructor(private formBuilder: FormBuilder, private auth: Auth) {
    if (auth) {
      this.user = authState(this.auth);
      this.userDisposable = authState(this.auth).pipe(
        traceUntilFirst('auth'),
        map(u => !!u)
      ).subscribe(isLoggedIn => {
      });
    }
  }

  ngOnInit(): void {
    this.buildForm();
  }

  private buildForm(): void {
    this.changePasswordForm = this.formBuilder.group({
      currentPassword: new FormControl('', [ Validators.required, Validators.minLength(6) ]),
      newPassword: new FormControl('', [ Validators.required, Validators.minLength(6) ]),
      confirmPassword: new FormControl('', [ Validators.required, Validators.minLength(6) ])
    });
  }

  onSaveChanges(): void {
    // Handle form submission here
    if (this.changePasswordForm.valid) {
      const currentPassword = this.changePasswordForm.get('currentPassword')!.value;
      const newPassword = this.changePasswordForm.get('newPassword')!.value;
      const confirmPassword = this.changePasswordForm.get('confirmPassword')!.value;

      if (this.user) {
        // Reauthenticate the user with their current password
        const credentials = EmailAuthProvider.credential(
          this.auth.currentUser!.email!,
          currentPassword
        );

        reauthenticateWithCredential(this.auth.currentUser!, credentials)
          .then(() => {
            if (newPassword === confirmPassword) {
              // Passwords match, proceed with changing the password
              updatePassword(this.auth.currentUser!, newPassword).then(() => {
                // Password updated successfully
              });
            } else {
              // Passwords do not match, handle accordingly
            }
          })
          .catch((error) => {
            // Handle any errors (e.g., incorrect current password)
          });
      }
    }
  }

  getErrorMessage() {
    if (this.changePasswordForm.get('currentPassword')?.hasError('required')) {
      return 'You must enter a value';
    }
    if (this.changePasswordForm.get('newPassword')?.hasError('required')) {
      return 'You must enter a value';
    }

    return this.changePasswordForm.get('newPassword')?.hasError('min-length') ? 'Not a valid password' : '';
  }
}
