<section>
  <div>
    <mat-card class="avatar-name-container">
      <mat-card-header>
        <div mat-card-avatar class="avatar">
          <mat-icon class="material-icons md-48">account_circle </mat-icon>
        </div>
        <mat-card-title>{{ (this.user | async)?.displayName }}</mat-card-title>
        <mat-card-subtitle>{{ (this.user | async)?.uid }}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-actions align="end">
        <button mat-button>Upload new avatar</button>
      </mat-card-actions>
    </mat-card>
  </div>
</section>
<mat-divider></mat-divider>
<section>
  <form
    [formGroup]="nameChangeForm"
    (ngSubmit)="onSaveChanges()"
    class="name-change-form"
  >
    <div class="grid-container">
      <mat-form-field class="full-width">
        <mat-label>Company Name</mat-label>
        <input
          matInput
          placeholder="Company Name"
          formControlName="companyName"
          class="mat-input-element"
        />
      </mat-form-field>

      <mat-form-field class="half-width">
        <mat-label>First Name</mat-label>
        <input
          matInput
          placeholder="First Name"
          formControlName="firstName"
          class="mat-input-element"
        />
      </mat-form-field>

      <mat-form-field class="half-width">
        <mat-label>Last Name</mat-label>
        <input
          matInput
          placeholder="Last Name"
          formControlName="lastName"
          class="mat-input-element"
        />
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Email Address</mat-label>
        <input
          matInput
          placeholder="Email Address"
          formControlName="email"
          class="mat-input-element"
        />
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Phone Number</mat-label>
        <input
          matInput
          placeholder="Phone Number"
          formControlName="phoneNumber"
          class="mat-input-element"
        />
      </mat-form-field>
    </div>

    <button mat-raised-button color="primary" type="submit">
      Save Changes
    </button>
  </form>
</section>
<mat-divider></mat-divider>
<!-- <section>
  <dashboard-notification-settings></dashboard-notification-settings>
</section>
<mat-divider></mat-divider>
<section>
  <div>
    <h6>Delete your account</h6>
    <p>
      Before deleting your account, please not that if you delete your account,
      CoaxDesigns cannot recover it.
    </p>
  </div>
  <div><button mat-raised-button color="primary">Delete</button></div>
</section> -->
