<nav aria-label="Top Toolbar" class="navbar-header">
  <!-- <div class="skip-link-wrapper cdk-visually-hidden ng-star-inserted">
    <a
      _ngcontent-ng-c3202968170=""
      mat-raised-button=""
      color="accent"
      mat-ripple-loader-uninitialized=""
      mat-ripple-loader-class-name="mat-mdc-button-ripple"
      class="mdc-button mdc-button--raised mat-mdc-raised-button mat-accent mat-mdc-button-base"
      href="/#homepage-header"
      aria-disabled="false"
      ><span class="mat-mdc-button-persistent-ripple mdc-button__ripple"></span
      ><span class="mdc-button__label"> Skip to main content </span
      ><span class="mat-mdc-focus-indicator"></span
      ><span class="mat-mdc-button-touch-target"></span
    ></a>
  </div> -->
  <a
    mat-button=""
    routerlink="/"
    aria-label="Angular Material"
    class="mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base"
    mat-ripple-loader-uninitialized
    mat-ripple-loader-class-name="mat-mdc-button-ripple"
    href="/"
    aria-disabled="false"
    ><span class="mat-mdc-button-persistent-ripple mdc-button__ripple"></span
    ><img
      matbuttonicon=""
      src="../../../assets/img/homepage/angular-white-transparent.svg"
      alt="angular"
      aria-hidden="true"
      class="logo"/><span class="mdc-button__label"
      ><span _ngcontent-ng-c3202968170>CoaxDesigns</span></span
    ><span class="mat-mdc-focus-indicator"></span
    ><span class="mat-mdc-button-touch-target"></span
  ></a>
  <div class="flex-spacer"></div>
  <a
    mat-button
    href="https://github.com/angular/components"
    aria-label="GitHub Repository"
    class="docs-button docs-navbar-hide-small mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base"
    mat-ripple-loader-uninitialized=""
    mat-ripple-loader-class-name="mat-mdc-button-ripple"
    aria-disabled="false"
    ><span class="mat-mdc-button-persistent-ripple mdc-button__ripple"></span
    ><img
      _ngcontent-ng-c3202968170=""
      matbuttonicon=""
      src="../../../assets/img/homepage/github-circle-white-transparent.svg"
      alt="angular"
      aria-hidden="true"
      class="logo"/><span class="mdc-button__label"> GitHub </span
    ><span class="mat-mdc-focus-indicator"></span
    ><span class="mat-mdc-button-touch-target"></span></a
  ><a
    mat-icon-button
    href="https://github.com/angular/components"
    aria-label="GitHub Repository"
    class="mdc-icon-button mat-mdc-icon-button mat-unthemed mat-mdc-button-base"
    mat-ripple-loader-uninitialized
    mat-ripple-loader-class-name="mat-mdc-button-ripple"
    aria-disabled="false"
    ><span class="mat-mdc-button-persistent-ripple mdc-button__ripple"></span
    ><img
      matbuttonicon
      src="../../../assets/img/homepage/github-circle-white-transparent.svg"
      alt="angular"
      aria-hidden="true"
      class="logo docs-navbar--github-logo"/><span
      class="mdc-button__label"
    ></span
    ><span class="mat-mdc-focus-indicator"></span
    ><span class="mat-mdc-button-touch-target"></span
  ></a>
</nav>
<!-- <mat-toolbar color="primary"  class="mat-elevation-z6">
  <mat-toolbar-row>
    <span>My App</span>
    <span class="spacer"></span>
    <button mat-icon-button>
      <mat-icon>search</mat-icon>
    </button>
    <button mat-icon-button>
      <mat-icon>account_circle</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar> -->
