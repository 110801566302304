import { Component, Input } from '@angular/core';
import { Auth, User, authState, signOut } from '@angular/fire/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { EMPTY, Observable, Subscription, map } from 'rxjs';
import { traceUntilFirst } from '@angular/fire/compat/performance';

@Component({
  selector: 'dashboard-account-general',
  templateUrl: './account-general.component.html',
  styleUrls: [ './account.component.scss' ]
})
export class AccountGeneralComponent {
  nameChangeForm!: FormGroup;


  private readonly userDisposable: Subscription | undefined;
  public readonly user: Observable<User | null> = EMPTY;

  firstName = '';
  lastName = '';
  email = '';
  phoneNumber = '';

  notifications = [
    { title: 'Notification Title 1', timestamp: 'Today, 9:00 AM', content: 'Content of notification 1', isActive: true },
    { title: 'Notification Title 2', timestamp: 'Yesterday, 3:30 PM', content: 'Content of notification 2', isActive: false },
    // Add more notifications as needed
  ];

  constructor(private formBuilder: FormBuilder, private auth: Auth) {
    if (auth) {
      this.user = authState(this.auth);
      this.userDisposable = authState(this.auth).pipe(
        traceUntilFirst('auth'),
        map(u => !!u)
      ).subscribe(isLoggedIn => {
        // this.showLoginButton = !isLoggedIn;
        // this.showLogoutButton = isLoggedIn;
      });
    }
  }

  ngOnInit() {
    // Initialize the form and define validation rules
    this.nameChangeForm = this.formBuilder.group({
      firstName: [ '', Validators.required ],
      lastName: [ '', Validators.required ],
      email: [ '', [ Validators.required, Validators.email ] ],
      phoneNumber: [ '' ]
    });
  }

  ngOnDestroy(): void {
    if (this.userDisposable) {
      this.userDisposable.unsubscribe();
    }
  }

  async logout() {
    return await signOut(this.auth);
  }

  onSaveChanges() {
    if (this.nameChangeForm.valid) {
      // Handle form submission here (e.g., send data to a server)
      console.log('Form data submitted:', this.nameChangeForm.value);
    }
  }
}
