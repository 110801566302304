import { Component } from '@angular/core';

@Component({
  selector: 'dashboard-overview',
  templateUrl: './overview.component.html',
  styleUrls: [ './overview.component.scss' ]
})
export class OverviewComponent {

}
