import { Component } from '@angular/core';

@Component({
  selector: 'dashboard-cms',
  templateUrl: './cms.component.html',
  styleUrls: [ './cms.component.scss' ]
})
export class CMSComponent {

}
