<mat-tab-group
  mat-stretch-tabs="false"
  mat-align-tabs="start"
  dynamicHeight
  animationDuration="0ms"
  fitInkBarToContent
  class="account-container"
>
  <mat-tab label="general">
    <!-- <dashboard-account-general [uid]="uid$"></dashboard-account-general> -->
    <dashboard-account-general></dashboard-account-general>
  </mat-tab>
  <mat-tab label="billing">
    <dashboard-account-billing></dashboard-account-billing>
  </mat-tab>
  <mat-tab label="security">
    <dashboard-account-security></dashboard-account-security>
  </mat-tab>
</mat-tab-group>
