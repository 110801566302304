import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'dashboard-bootstrap',
  templateUrl: './dashboard-bootstrap.component.html',
  styleUrls: [ './dashboard-bootstrap.component.scss' ]
})
export class DashboardBootstrapComponent {

  // constructor(private router: Router) { }

}
