import { Component } from '@angular/core';

@Component({
  selector: 'dashboard-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: [ './navbar.component.scss' ]
})
export class NavbarComponent {

}
