import { ApplicationRef, Component, OnInit } from '@angular/core';
// import { Router } from '@angular/router';

// import { AccountBillingComponent } from './account-billing.component';
// import { AccountGeneralComponent } from './account-general.component';
// import { AccountSecurityComponent } from './account-security.component';
// import { loadStripe, Stripe } from '@stripe/stripe-js';
// import { environment } from 'projects/environments/environment';

// import { StripeService } from 'ngx-stripe';
// import { HttpClient } from '@angular/common/http';
// const stripe = await loadStripe(environment.stripePublishableKey);

@Component({
  selector: 'dashboard-account',
  templateUrl: './account.component.html',
  styleUrls: [ './account.component.scss' ]
})
export class AccountComponent {
  // stripePromise: Promise<Stripe | null>;

  // constructor(private http: HttpClient) {
  //   // this.stripePromise = loadStripe(environment.stripePublishableKey);
  // }

  // createCustomerPortalSession() {
  //   return this.http.post<any>(billing.stripe.com/p/login/dR65kxdClePDfSM144)
  // }
}
