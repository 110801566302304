import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { redirectUnauthorizedTo, AuthGuard, canActivate, redirectLoggedInTo } from '@angular/fire/auth-guard';

import { OverviewComponent } from './overview/overview.component';
import { AccountComponent } from './account/account.component';
import { CMSComponent } from './cms/cms.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo([ 'login' ]);
const redirectLoggedInToDashboard = () => redirectLoggedInTo([ '' ]);

const routes: Routes = [
  // { path: '', redirectTo: '/login', pathMatch: 'full' },
  // { path: 'login', component: LoginComponent, outlet: 'primary' },
  { path: 'login', component: LoginComponent, outlet: 'primary', ...canActivate(redirectLoggedInToDashboard) },
  {
    // path: 'dashboard', component: DashboardComponent, outlet: 'primary',
    path: '', component: DashboardComponent, outlet: 'primary', ...canActivate(redirectUnauthorizedToLogin),
    children: [
      //   // { path: 'overview', component: OverviewComponent },
      { path: 'account', component: AccountComponent },
      { path: '', redirectTo: '/account', pathMatch: 'prefix' }
      // { path: 'dashboard', redirectTo: '/account', pathMatch: 'prefix' },
      // { path: 'cms', component: CMSComponent },
    ]
  },
  { path: '**', component: LoginComponent },
  // { path: '**', component: LoginComponent, ...canActivate(redirectLoggedInToDashboard) },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class DashboardRoutingModule { }
