import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'dashboard-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: [ './notification-settings.component.scss' ]
})
export class NotificationSettingsComponent {
  isChecked = true;
  formGroup = this._formBuilder.group({
    enableWifi: '',
    acceptTerms: [ '', Validators.requiredTrue ],
  });

  constructor(private _formBuilder: FormBuilder) { }

  alertFormValues(formGroup: FormGroup) {
    alert(JSON.stringify(formGroup.value, null, 2));
  }
}
