import { Component } from '@angular/core';
import { Auth, signOut } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'dashboard-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: [ './sidenav.component.scss' ]
})
export class SidenavComponent {

  constructor(private auth: Auth, private router: Router) { }

  async logout() {
    this.auth.currentUser!.isAnonymous ?
      await this.auth.currentUser!.delete().then(async () => {
        await signOut(this.auth).then(() => {
          this.router.navigate([ 'login' ]);
        });
      })
      :
      await signOut(this.auth).then(() => {
        this.router.navigate([ 'login' ]);
      })
  }
}
