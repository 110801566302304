<mat-sidenav-container>
  <mat-sidenav #sidenav mode="side" [fixedInViewport]="true" opened>
    <dashboard-sidenav></dashboard-sidenav>
  </mat-sidenav>
  <mat-sidenav-content class="dashboard-content">
    <dashboard-navbar class="mat-elevation-z6"></dashboard-navbar>
    <router-outlet></router-outlet>
    <!-- <dashboard-account></dashboard-account> -->
  </mat-sidenav-content>
</mat-sidenav-container>
