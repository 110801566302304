import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DashboardMaterialModule } from './dashboard-material/dashboard-material.module';
import { DashboardRoutingModule } from './dashboard-routing.module';

import { DashboardComponent } from './dashboard.component';
import { NavbarComponent } from './navbar/navbar.component';
import { OverviewComponent } from './overview/overview.component';
import { AccountComponent } from './account/account.component';
import { CMSComponent } from './cms/cms.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccountGeneralComponent } from './account/account-general.component';
import { AccountBillingComponent } from './account/account-billing.component';
import { AccountSecurityComponent } from './account/account-security.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../../../environments/environment';

import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
// import { connectFunctionsEmulator, FunctionsModule, getFunctions, provideFunctions } from '@angular/fire/functions';
import { getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { AuthModule, connectAuthEmulator, getAuth, provideAuth } from '@angular/fire/auth';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotificationSettingsComponent } from './notification-settings/notification-settings.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { AuthGuardModule } from '@angular/fire/auth-guard';
import { LoginComponent } from './login/login.component';
import { DashboardBootstrapComponent } from './dashboard-bootstrap.component';

@NgModule({
  declarations: [
    DashboardBootstrapComponent,
    DashboardComponent,
    NavbarComponent,
    OverviewComponent,
    AccountComponent,
    CMSComponent,
    AccountGeneralComponent,
    AccountBillingComponent,
    AccountSecurityComponent,
    NotificationSettingsComponent,
    SidenavComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    provideAnalytics(() => getAnalytics()),
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.production,
    //   registrationStrategy: 'registerWhenStable:30000'
    // }),
    provideAuth(() => {
      const auth = getAuth();
      if (environment.useEmulators) {
        connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
      }
      return auth;
    }),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfigDashboard)),
    // provideFunctions(() => {
    //   const functions = getFunctions();
    //   if (environment.useEmulators) {
    //     connectFunctionsEmulator(functions, 'localhost', 5001);
    //   }
    //   return functions;
    // }),
    BrowserAnimationsModule,
    DashboardRoutingModule,
    DashboardMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    AuthModule,
    AuthGuardModule,
    // FunctionsModule
  ],
  providers: [],
  bootstrap: [ DashboardBootstrapComponent ]
})
export class DashboardModule { }
