<!-- <div>
  <div>
    <h6>Auto renewal</h6>
    <p>
      All subscriptions are set to automatically renew, unless you cancel before
      the next renewal date.
    </p>
  </div>
  <div><button mat-raised-button color="primary">Enable</button></div>
</div> -->
<div>
  <!-- <div>
    <h4>Change password</h4>
    <p>
      you can always change your password for security reasons or reset your
      password in case you forgot it
    </p>
    <button mat-raised-button color="primary">Enable</button>
  </div> -->
  <form [formGroup]="changePasswordForm" (ngSubmit)="onSaveChanges()">
    <mat-form-field appearance="fill">
      <mat-label>Current Password</mat-label>
      <input
        matInput
        formControlName="currentPassword"
        type="password"
        required
      />
      <mat-error *ngIf="changePasswordForm.get('currentPassword')?.invalid"
        >{{ getErrorMessage() }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>New Password</mat-label>
      <input matInput formControlName="newPassword" type="password" />
      <mat-hint>Minimum 6 characters</mat-hint>
      <mat-error *ngIf="changePasswordForm.get('newPassword')?.invalid"
        >{{ getErrorMessage() }}
      </mat-error>
      <mat-error *ngIf="changePasswordForm.get('newPassword')?.invalid"
        >{{ getErrorMessage() }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Confirm New Password</mat-label>
      <input matInput formControlName="confirmPassword" type="password" />
      <mat-error *ngIf="changePasswordForm.get('confirmPassword')?.invalid"
        >{{ getErrorMessage() }}
      </mat-error>
    </mat-form-field>
    <button mat-raised-button color="primary" type="submit">
      Update Password
    </button>
  </form>
</div>

<div>
  <!-- <mat-card>
    <mat-card-header>
      <div mat-card-title>Notifications</div>
    </mat-card-header>
    <mat-card-content>
      <mat-list>
        <mat-list-item *ngFor="let notification of notifications">
          <div mat-line>
            {{ notification.title }}
          </div>
          <div mat-line>
            {{ notification.timestamp }}
          </div>
          <div mat-line>
            <mat-slide-toggle [(ngModel)]="notification.isActive">
              {{ notification.isActive ? "Active" : "Inactive" }}
            </mat-slide-toggle>
          </div>
        </mat-list-item>
      </mat-list>
    </mat-card-content>
  </mat-card> -->
</div>
<div></div>
