<mat-card class="mat-elevation-z10">
  <mat-card-header>Notifications</mat-card-header>
  <mat-card-content>
    <div class="notification-row">
      <h6>Annoucements</h6>
      <mat-slide-toggle [(ngModel)]="isChecked">
        {{ isChecked }}</mat-slide-toggle
      >
    </div>
    <div class="notification-row">
      <h6>Feedback Requests</h6>
      <mat-slide-toggle [(ngModel)]="isChecked">
        {{ isChecked }}</mat-slide-toggle
      >
    </div>
    <div class="notification-row">
      <h6>Billing and Account</h6>
      <mat-slide-toggle [(ngModel)]="isChecked">
        {{ isChecked }}</mat-slide-toggle
      >
    </div>
  </mat-card-content>
</mat-card>
