<div>
  <mat-icon
    fontIcon="account_circle_outlined"
    icon
    class="material-icons md-48"
  >
  </mat-icon>
</div>
<mat-nav-list aria-label="Top Toolbar" class="nav-list">
  <mat-list-item>
    <mat-divider></mat-divider>
  </mat-list-item>
  <mat-list-item>
    <mat-icon fontIcon="person" class="material-icons"></mat-icon>
    <a
      mat-button
      routerlinkactive="docs-navbar-header-item-selected"
      class="docs-navbar-hide-small docs-button mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base ng-star-inserted"
      mat-ripple-loader-class-name="mat-mdc-button-ripple"
      routerLink="/dashboard/account"
      aria-disabled="false"
      ><span class="mat-mdc-button-persistent-ripple mdc-button__ripple"></span
      ><span class="mdc-button__label">Acount</span
      ><span class="mat-mdc-focus-indicator"></span
      ><span class="mat-mdc-button-touch-target"></span
      ><span class="mat-ripple mat-mdc-button-ripple"></span
    ></a>
  </mat-list-item>
  <mat-list-item>
    <button mat-raised-button (click)="logout()">log out</button>
  </mat-list-item>
</mat-nav-list>
