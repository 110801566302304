import { Component, OnInit, OnDestroy, PLATFORM_ID, isDevMode, ApplicationRef, Optional } from '@angular/core';
import { EmailAuthProvider, signInWithRedirect } from '@angular/fire/auth';
import { EMPTY, Observable, Subscription, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { trace, traceUntilFirst } from '@angular/fire/compat/performance';
import { Inject } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { Auth, GoogleAuthProvider, User, authState, signInAnonymously, signInWithPopup, signOut } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'dashboard-login',
  templateUrl: './login.component.html',
  styleUrls: [ './login.component.scss' ]
})
export class LoginComponent implements OnInit, OnDestroy {
  title = isDevMode() ? 'Development' : 'Auth';
  private readonly userDisposable: Subscription | undefined;
  public readonly user: Observable<User | null> = EMPTY;


  showLoginButton = false;
  showLogoutButton = false;

  constructor(appRef: ApplicationRef, private titleService: Title, private auth: Auth, private router: Router, @Optional() @Inject(PLATFORM_ID) platformId: object) {
    this.titleService.setTitle(this.title);

    if (isDevMode()) {
      appRef.isStable.pipe(
        debounceTime(200),
        distinctUntilChanged(),
      ).subscribe(it => {
        console.log('isStable', it);
      });
    }

    if (auth) {
      this.user = authState(this.auth);
      this.userDisposable = authState(this.auth).pipe(
        traceUntilFirst('auth'),
        map(u => !!u)
      ).subscribe(isLoggedIn => {
        this.showLoginButton = !isLoggedIn;
        this.showLogoutButton = isLoggedIn;
      });
    }
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    if (this.userDisposable) {
      this.userDisposable.unsubscribe();
    }
  }

  async login() {
    return await signInWithPopup(this.auth, new GoogleAuthProvider());
  }

  async loginAnonymously() {
    await signInAnonymously(this.auth).then(() => {
      // // this.router.navigate([ 'account' ]);
      // window.location.href = `https://billing.stripe.com/p/login/dR65kxdClePDfSM144?prefilled_email=${this.auth.currentUser!.email}`;
      window.location.href = 'https://billing.stripe.com/p/login/dR65kxdClePDfSM144?prefilled_email=bbonner@coaxdes.com';
    });
    // await this.router.navigate([ 'account' ]);
  }

  async logout() {
    // if (this.auth.currentUser!.isAnonymous) {
    //   return await this.auth.currentUser!.delete().then(async () => {
    //     await signOut(this.auth);
    //   });
    // } else {
    //   return await signOut(this.auth);
    // }
    await this.auth.currentUser!.delete().then(async () => {
      // return await signOut(this.auth);
    });
  }
}
