<p>
  Auth!
  <code>{{ (user | async)?.uid }}</code>
  <button mat-raised-button (click)="login()" *ngIf="showLoginButton">
    Log in with Google
  </button>
  <button
    mat-raised-button
    (click)="loginAnonymously()"
    *ngIf="showLoginButton"
  >
    Log in anonymously
  </button>
  <button mat-raised-button (click)="logout()" *ngIf="showLogoutButton">
    Log out
  </button>
</p>
